import { Controller } from '@hotwired/stimulus'

const LOADING_CLASS = 'is-loading'
const HIDE_CLASS = 'is-hidden'
const OPTION_TITLE_SELECTOR = '[data-option-title]'

export default class extends Controller {
  static targets = [
    'optionTemplate',
    'valueInput',
    'availableContainer',
    'availableOption',
    'selectedContainer',
    'selectedOption',
    'paginationMark',
    'addOption'
  ]

  static values = {
    url: String,
    inputName: String
  }

  initialize () {
    this.query = ''
    this.paginationMarkObserver = new IntersectionObserver(this.paginationMarkObserverCallback.bind(this))
    this.searchThrottleTimeout = null
    this.selectedValueSet = new Set()
    this.isConnected = false
  }

  connect () {
    this.isConnected = true
  }

  disconnect () {
    this.paginationMarkObserver.disconnect()
    this.currentRequest?.abort()

    clearTimeout(this.searchThrottleTimeout)
    this.isConnected = false
  }

  paginationMarkTargetConnected (el) {
    this.paginationMarkObserver.observe(el)
  }

  paginationMarkTargetDisconnected (el) {
    this.paginationMarkObserver.unobserve(el)
  }

  paginationMarkObserverCallback (entries) {
    if (!entries[0].isIntersecting) return

    this.performPageQuery()
  }

  valueInputTargetConnected (el) {
    this.selectedValueSet.add(el.value)

    if (this.isConnected) {
      this.dispatch('change', { detail: { count: this.selectedValueSet.size } })
    }
  }

  valueInputTargetDisconnected (el) {
    this.selectedValueSet.delete(el.value)

    if (this.isConnected) {
      this.dispatch('change', { detail: { count: this.selectedValueSet.size } })
    }
  }

  availableOptionTargetConnected (el) {
    const value = el.getAttribute(this.data.getAttributeNameForKey('valueParam'))
    el.classList.toggle(HIDE_CLASS, this.selectedValueSet.has(value))
  }

  select ({ currentTarget, params: { value } }) {
    const text = (currentTarget.querySelector(OPTION_TITLE_SELECTOR) || currentTarget)?.textContent
    this.insertSelectedOption(value, text)
    currentTarget.classList.add(HIDE_CLASS)
  }

  add ({ currentTarget }) {
    this.insertSelectedOption(this.query, this.query)
    currentTarget.classList.add(HIDE_CLASS)
  }

  insertSelectedOption (value, text) {
    const selectedOption = this.optionTemplateTarget.content.firstChild.cloneNode(true)
    selectedOption.setAttribute(this.data.getAttributeNameForKey('valueParam'), value)

    const titleEl = selectedOption.querySelector(OPTION_TITLE_SELECTOR) || selectedOption
    titleEl.textContent = text

    const insertBeforeNode = this.selectedOptionTargets.find((el) => el.textContent > text)

    if (insertBeforeNode) {
      this.selectedContainerTarget.insertBefore(selectedOption, insertBeforeNode)
    } else {
      this.selectedContainerTarget.appendChild(selectedOption)
    }

    const valueInput = this.element.ownerDocument.createElement('input')
    valueInput.type = 'hidden'
    valueInput.name = this.inputNameValue
    valueInput.value = value
    valueInput.setAttribute(this.application.schema.targetAttributeForScope(this.identifier), 'valueInput')
    this.element.prepend(valueInput)
  }

  deselect ({ currentTarget, params: { value } }) {
    value = value.toString()

    const valueInput = this.valueInputTargets.find((el) => el.value === value)
    const availableOption = this.availableOptionTargets.find((el) => {
      return el.getAttribute(this.data.getAttributeNameForKey('valueParam')) === value
    })

    availableOption?.classList.remove(HIDE_CLASS)
    valueInput?.remove()
    currentTarget.remove()
  }

  search (event) {
    clearTimeout(this.searchThrottleTimeout)

    if (this.hasAddOptionTarget) {
      this.addOptionTarget.classList.add(HIDE_CLASS)
    }

    const query = event.target.value.trim()
    const token = query.toLowerCase()
    this.query = query

    let hasExactMatch = this.searchOptions(this.selectedOptionTargets, token)

    this.availableContainerTarget.innerHTML = ''
    this.element.classList.add(LOADING_CLASS)

    const throttledSearch = () => {
      this.performSearchQuery().complete(() => {
        hasExactMatch ||= this.searchOptions(this.availableOptionTargets, token)

        if (this.hasAddOptionTarget) {
          this.addOptionTarget.classList.toggle(HIDE_CLASS, hasExactMatch)
          const optionTitleEl = this.addOptionTarget.querySelector(OPTION_TITLE_SELECTOR) || this.addOptionTarget
          optionTitleEl.textContent = query
        }
      })
    }

    this.searchThrottleTimeout = setTimeout(throttledSearch, 200)
  }

  searchOptions (options, token) {
    if (token === '') {
      options.forEach((el) => el.classList.remove(HIDE_CLASS))
      return true
    }

    let hasExactMatch = false

    options.forEach((el) => {
      const text = el.textContent.trim().toLowerCase()

      if (text.includes(token)) {
        if (text === token) {
          hasExactMatch = true
        }

        el.classList.remove(HIDE_CLASS)
      } else {
        el.classList.add(HIDE_CLASS)
      }
    })

    return hasExactMatch
  }

  performSearchQuery () {
    this.currentRequest?.abort()

    const { query } = this

    return this.performQuery({ query }).success((responseText) => {
      $(this.availableContainerTarget).html(responseText)
    })
  }

  performPageQuery () {
    const { query } = this
    const { page } = this.paginationMarkTarget.dataset

    return this.performQuery({ query, page }).success((responseText) => {
      $(this.paginationMarkTarget).replaceWith(responseText)
    })
  }

  performQuery ({ query, page }) {
    return $.rails.ajax({
      url: this.urlValue,
      data: { query, page: page || 1 },
      dataType: 'html',
      beforeSend: (xhr) => {
        if (this.currentRequest) {
          return false
        }

        this.currentRequest = xhr
        this.element.classList.add(LOADING_CLASS)
      },
      complete: () => {
        this.currentRequest = null
        this.element.classList.remove(LOADING_CLASS)
      }
    })
  }
}
